import { Carousel, Slide } from '@zento-lib/components/Carousel';
import { Component, BaseComponent, Prop, namespace } from '@zento-lib/components';
import type { SliderBannerData } from 'theme/stores/cms/types';
import { AppContextStore, KEY as AppContextKey } from 'theme/@types/zento/stores/applicationContext';
import { HomepageStaticBanner } from '@zento/modules/molecule/Homepage/StaticBanner/StaticBanner';

import type { IHomepageSliderBanner } from './SliderBanner.d';
import style from './style.scss';

const appContextStore = namespace<AppContextStore>(AppContextKey);

/**
 * Homepage Slider Banner
 *
 * Renders a slider with images and custom text (specified by the shop administrator)
 */
@Component({})
export class HomepageSliderBanner extends BaseComponent<IHomepageSliderBanner, unknown> {
  /**
   * An object containing component information (like image path, banner content, etc.)
   */
  @Prop({ type: Object, required: true })
  sliderHeroData: SliderBannerData & { heading?: string; heading_position?: string };

  @appContextStore.Getter('isDesktop')
  isDesktop: boolean;

  render() {
    return this.sliderHeroData.slides.length > 0 ? (
      <section class={style.widgetSlider}>
        <div
          class={{
            [style.container]: true,
            [style.smallContainer]: this.sliderHeroData.width === 'small',
            [style.largeContainer]: this.sliderHeroData.width === 'large',
            [style.fullContainer]: this.sliderHeroData.width === 'full_width',
          }}>
          {this.sliderHeroData.heading ? (
            <h2
              class={{
                [style.title]: true,
                [style.centerTitle]: this.sliderHeroData.heading_position === 'center',
                [style.rightTitle]: this.sliderHeroData.heading_position === 'right',
              }}
              key='product-widget-title'>
              <span>{this.sliderHeroData.heading}</span>
            </h2>
          ) : null}

          <Carousel
            perPage={parseFloat(this.sliderHeroData.perPageDesktop) || 1}
            perPageCustom={[
              [320, parseFloat(this.sliderHeroData.perPageMobilePortrait) || 1],
              [480, parseFloat(this.sliderHeroData.perPageMobileLandscape) || 1],
              [767, parseFloat(this.sliderHeroData.perPageTablet) || 1],
              [1025, parseFloat(this.sliderHeroData.perPageDesktop) || 1],
            ]}
            mouseDrag={true}
            navigationEnabled={this.sliderHeroData.navigationEnabled}
            paginationEnabled={this.sliderHeroData.paginationEnabled}
            paginationColor={this.sliderHeroData.paginationColor}
            paginationActiveColor={this.sliderHeroData.paginationActiveColor}
            paginationSize={parseFloat(this.sliderHeroData.paginationSize)}
            centerMode={this.sliderHeroData.centerMode}
            scrollPerPage={this.isDesktop}
            easing='ease-in-out'
            speed={this.isDesktop ? this.sliderHeroData.speed ?? 500 : 200}
            autoplay={this.sliderHeroData.autoplay ?? false}
            loop={this.sliderHeroData.loop ?? false}
            autoplayTimeout={this.sliderHeroData.autoplayTimeout ?? 4000}
            class={style.widgetSliderItems}
            ref='sliderBannerCarousel'
            key='slider-banner-carousel'>
            {this.sliderHeroData.slides.map((data, index) => {
              const image = data.image.startsWith('/') ? data.image : '/' + data.image;

              return (
                <Slide class={style.widgetSliderItem} key={'banner-slider-' + index}>
                  <HomepageStaticBanner
                    bannerHeroData={{
                      button_label: data.buttonLabel,
                      button_link: data.buttonLink,
                      content_position: data.contentPosition,
                      gradient: data.gradient,
                      image,
                      content: data.content,
                      isSlider: true,
                      hasLink: data.hasLink,
                      textPosition: data.textPosition,
                    }}
                  />
                </Slide>
              );
            })}
          </Carousel>
        </div>
      </section>
    ) : null;
  }
}
