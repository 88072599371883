import { Carousel, Slide } from '@zento-lib/components/Carousel';
import { Component, BaseComponent, Prop, namespace } from '@zento-lib/components';
import type { ProductsData } from 'theme/stores/cms/types';
import { AppContextStore, KEY as AppContextKey } from 'theme/@types/zento/stores/applicationContext';

import { ProductBoxGrid } from '../../../organism/ProductBoxGrid/ProductBoxGrid';
import type { FilterType } from '../../../molecule/types/Category';
import { Link } from '../../Link/Link';

import type { IProductWidget } from './Widget.d';
import style from './style.scss';

const appContextStore = namespace<AppContextStore>(AppContextKey);

/**
 * Product Widget
 *
 * Renders a carousel with specific products
 */
@Component({})
export class ProductWidget extends BaseComponent<IProductWidget, unknown> {
  private static T = {
    seeAll: 'see_all',
  };

  /**
   * An object of filters for image swatch selection
   */
  @Prop({ type: Object, required: false })
  chosenFilters?: { [filterType in FilterType['type']]: FilterType };

  /**
   * Widget data
   */
  @Prop({ type: Object, required: true })
  widgetData: ProductsData;

  @appContextStore.Getter('isMobile')
  isMobile: boolean;

  @appContextStore.Getter('isTablet')
  isTablet: boolean;

  @appContextStore.Getter('isDesktop')
  isDesktop: boolean;

  data() {
    return {
      componentLoaded: false,
    };
  }

  mounted() {
    this.broadcast('analytics/product-list-track', {
      products: Object.values(this.widgetData.items),
      list: this.widgetData.heading || '',
      category: this.widgetData.heading || '',
    });
    this.broadcast('analytics/product-list-track-custom', {
      products: Object.values(this.widgetData.items),
      list: this.widgetData.heading || '',
      category: this.widgetData.heading || '',
    });

    this.$nextTick(() => {
      this.$data.componentLoaded = true;
    });

    // Determines bundle/grouped product and configures associated product for configurables
    Object.values(this.widgetData.items).map((product) => {
      if (product.type_id === 'bundle' || product.type_id === 'grouped') {
        if (product.price === 0) {
          this.$store.dispatch('product/setupAssociated', {
            product: product,
          });
        }
      }
    });
  }

  get gridValues() {
    const config = this.extended.$config.zento.productWidget;

    if (this.isMobile) {
      const mobile = parseInt(this.widgetData.perPageMobilePortrait || config.mobileProducts);
      return `repeat(${mobile}, ${100 / mobile}%)`;
    } else if ((this.isTablet && !this.isMobile) || !this.isDesktop) {
      const tablet = parseInt(this.widgetData.perPageTablet || config.tabletProducts);
      return `repeat(${tablet}, ${100 / tablet}%)`;
    } else if (this.isDesktop) {
      const desktop = parseInt(this.widgetData.perPageDesktop || config.desktopProducts);
      return `repeat(${desktop}, ${100 / desktop}%)`;
    }
  }

  get products() {
    const products = this.widgetData.items;

    if (this.widgetData.type === 'newProducts') {
      return Object.values(products).sort((a, b) => {
        if (a.created_at > b.created_at || a.entity_id > b.entity_id) {
          return -1;
        }

        if (b.created_at > a.created_at || b.entity_id > a.entity_id) {
          return 1;
        }

        return 0;
      });
    }

    return Object.values(products);
  }

  render() {
    const config = this.extended.$config.zento.images.productsThumbnails;

    return this.products?.length > 0 ? (
      <section class={style.widgetProducts}>
        <div class={style.container}>
          {this.widgetData.heading ? (
            <h2
              class={{
                [style.title]: true,
                [style.centerTitle]: this.widgetData.heading_position === 'center',
                [style.rightTitle]: this.widgetData.heading_position === 'right',
                [style.seeAllLeft]: this.widgetData.seeAllPosition === 'left',
                [style.seeAllRight]: this.widgetData.seeAllPosition === 'right',
                [style.seeAllAfter]:
                  this.widgetData.seeAllPosition === 'left' && this.widgetData.heading_position === 'left',
              }}
              key='product-widget-title'>
              <span>{this.widgetData.heading}</span>
              <div>
                {this.widgetData.seeAll ? (
                  <Link
                    to={
                      this.widgetData.follow === 'follow_link' &&
                      this.widgetData.follow_link &&
                      this.widgetData.follow_link?.length
                        ? this.widgetData.follow_link
                        : {
                            name: 'Widget List',
                            params: {
                              heading: this.widgetData.heading,
                              products: this.products,
                            },
                          }
                    }
                    class={{
                      [style.seeAll]: true,
                      [style.centerSeeAll]: this.widgetData.seeAllPosition === 'center',
                      [style.rightSeeAll]: this.widgetData.seeAllPosition === 'right',
                    }}
                    key='see-all'>
                    {this.getTranslation({ id: ProductWidget.T.seeAll })}
                  </Link>
                ) : null}
              </div>
            </h2>
          ) : null}

          {this.$data.componentLoaded ? (
            this.widgetData.navigationEnabled ? (
              <Carousel
                perPage={parseFloat(this.widgetData.productsToDisplay)}
                perPageCustom={[
                  [320, parseFloat(this.widgetData.perPageMobilePortrait)],
                  [480, parseFloat(this.widgetData.perPageMobileLandscape)],
                  [767, parseFloat(this.widgetData.perPageTablet)],
                  [1025, parseFloat(this.widgetData.perPageDesktop)],
                ]}
                mouseDrag={true}
                navigationEnabled={this.widgetData.navigationEnabled}
                paginationEnabled={this.widgetData.paginationEnabled}
                paginationColor={this.widgetData.paginationColor}
                paginationActiveColor={this.widgetData.paginationActiveColor}
                paginationSize={parseFloat(this.widgetData.paginationSize)}
                centerMode={this.widgetData.centerMode}
                scrollPerPage={this.isDesktop}
                autoplay={config.carouselAutoplay && this.isDesktop}
                loop={config.carouselLoop && this.isDesktop}
                easing='ease-in-out'
                speed={this.isDesktop ? 500 : 200}
                ref='carouselBestSellers'
                class={{
                  [style.widgetProductsItems]: true,
                  [style.centerMode]: this.widgetData.centerMode,
                }}
                key='product-widget-carousel'>
                {this.products.map((product, index) => {
                  return (
                    <Slide key={product.entity_id}>
                      <ProductBoxGrid product={product} postion={index} widgetsPrice={true} />
                    </Slide>
                  );
                })}
              </Carousel>
            ) : (
              <ul class={[style.container, style.grid]} style={{ 'grid-template-columns': this.gridValues }}>
                {this.products.map((product, index) => {
                  return (
                    <li key={product.entity_id}>
                      <ProductBoxGrid product={product} postion={index} widgetsPrice={true} />
                    </li>
                  );
                })}
              </ul>
            )
          ) : null}
        </div>
      </section>
    ) : null;
  }
}
