import { htmlDecode } from '@vue-storefront/core/filters/html-decode';
import { Component, BaseComponent } from '@zento-lib/components';
import { CmsStore } from 'theme/stores/cms/cms';
import { StoreConfig } from 'theme/stores/storeConfig/storeConfig';
import { AnalyticsConversionApiStore } from 'theme/stores/analyticsConversionApi/analyticsConversionApi';

@Component({})
export class CmsPageMixin extends BaseComponent<unknown, unknown> {
  private cmsStore = new CmsStore();
  private storeConfig = new StoreConfig();
  private analyticsConversionApiStore = new AnalyticsConversionApiStore();

  async serverPrefetch() {
    return await this.fetchCmsPageInfo();
  }

  async fetchCmsPageInfo() {
    return await this.cmsStore.fetchCmsPageData(this.$route.params.slug ?? 'home');
  }

  beforeMount() {
    this.onBroadcast('user-after-loggedin', this.onAfterLoggedIn);
  }

  async mounted() {
    const pageData = await this.fetchCmsPageInfo();

    if (!pageData?.[this.$route.params.slug ?? 'home']) {
      this.$router.push('/page-not-found');
    }

    return pageData;
  }

  beforeDestroy() {
    this.offBroadcast('user-after-loggedin', this.onAfterLoggedIn);
  }

  metaInfo() {
    const page = this.pageData;

    if (page) {
      const title = htmlDecode(page.metaTitle || page.title);
      const storeUrl = this.extended.$config.zento.theme.storeData.storeUrl;

      return {
        link: [{ rel: 'canonical', href: this.$route.path ? storeUrl + this.$route.path : storeUrl }],
        title: title,
        meta: [
          { vmid: 'title', name: 'title', content: title },
          { vmid: 'description', name: 'description', content: htmlDecode(page.metaDescription ?? title) },
          { vmid: 'keywords', name: 'keywords', content: htmlDecode(page.metaKeywords ?? '') },
          { vmid: 'og:image', property: 'og:image', content: storeUrl + this.extended.$config.zento.html.ogImage },
          { vmid: 'og:title', property: 'og:title', content: title.replace(/<[^>]*>?/gm, '') },
          { vmid: 'og:description', property: 'og:description', content: htmlDecode(page.metaDescription ?? '') },
          { vmid: 'og:url', property: 'og:url', content: this.$route.path ? storeUrl + this.$route.path : storeUrl },
        ],
      };
    }
  }

  get pageData() {
    return this.cmsStore.getPageByIdentifier(this.$route.params.slug ?? 'home');
  }

  private onAfterLoggedIn() {
    if (this.storeConfig.storeConfigurations.analyticsDataboxGeneralActive) {
      const user = this.$store.state.user.current;

      if (user) {
        this.analyticsConversionApiStore.send({
          eventName: 'Login',
          userData: {
            id: user.id.toString() ?? user.email,
            created_at: user.created_at,
          },
        });
      }
    }
  }
}
