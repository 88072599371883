import { Component, BaseComponent, Prop, namespace } from '@zento-lib/components';
import { Carousel, Slide } from '@zento-lib/components/Carousel';
import type { TestimonialsData } from 'theme/stores/cms/types';
import { AppContextStore, KEY as appContextKey } from 'theme/@types/zento/stores/applicationContext';

import { MainImage } from '../../../atom/MainImage/MainImage';

import type { ITestimonials } from './Testimonials.d';
import style from './style.scss';

const appContextStore = namespace<AppContextStore>(appContextKey);

/**
 * Homepage Customer Reviews
 *
 * Renders an image with custom text (specified by the shop administrator)
 */
@Component({})
export class Testimonials extends BaseComponent<ITestimonials, unknown> {
  /**
   * An array containing component information (like image path, title, content, etc.)
   */
  @Prop({ type: Object, required: true })
  testimonialsData: TestimonialsData;

  @appContextStore.Getter('isDesktop')
  isDesktop: boolean;

  data() {
    return {
      componentLoaded: false,
      testimonialsContent: JSON.parse(this.testimonialsData.content.replace(/&quot;/g, '"')),
    };
  }

  mounted() {
    this.$nextTick(() => {
      this.$data.componentLoaded = true;
    });
  }

  get imageSizes() {
    return this.extended.$config.zento.images.testimonials;
  }

  render() {
    return (
      <section class={style.testimonials} key={this.testimonialsData.heading}>
        {this.testimonialsData.heading ? (
          <h2
            class={{
              [style.centerTitle]: this.testimonialsData.heading_position === 'center',
              [style.rightTitle]: this.testimonialsData.heading_position === 'right',
            }}
            key='top-categories-title'>
            {this.testimonialsData.heading}
          </h2>
        ) : null}

        {this.$data.componentLoaded ? (
          <Carousel
            perPage={1}
            mouseDrag={false}
            navigationEnabled={true}
            paginationEnabled={true}
            paginationColor='#1C1C1C66'
            paginationActiveColor='#1C1C1C'
            centerMode={true}
            scrollPerPage={this.isDesktop}
            easing='ease-in-out'
            speed={this.isDesktop ? 500 : 200}
            ref='carouselNew'
            class={style.testimonialsItems}>
            {this.$data.testimonialsContent.map((item) => {
              const imageTestimonial = {
                src: item.image,
                loading: '',
              };

              return (
                <Slide key={item.name} class={style.testimonialsItem}>
                  <MainImage
                    image={imageTestimonial}
                    alt={item.alt}
                    folder=''
                    width={this.imageSizes.width}
                    height={this.imageSizes.height}
                    tabletWidth={this.imageSizes.tabletWidth}
                    tabletHeight={this.imageSizes.tabletHeight}
                    desktopWidth={this.imageSizes.desktopWidth}
                    desktopHeight={this.imageSizes.desktopHeight}
                    class={style.customerImg}
                    key={item.alt}
                  />

                  <div key='customer-content'>
                    <div class={style.content}>
                      <p key='customer-content-text'>{item.description}</p>

                      <span>{item.name}</span>
                    </div>
                  </div>
                </Slide>
              );
            })}
          </Carousel>
        ) : null}
      </section>
    );
  }
}
