import { Component, BaseComponent, Prop, namespace } from '@zento-lib/components';
import { AppContextStore, KEY as AppContextKey } from 'theme/@types/zento/stores/applicationContext';

import type { IOverwriteStyle } from './OverwriteStyle.d';

const appContextStore = namespace<AppContextStore>(AppContextKey);

type Border = { style?: 'solid' | 'dashed' | 'dotted'; width?: number; color?: string };

interface IOverwrites {
  background?: string;
  backgroundImg?: string;
  border: [Border, Border, Border, Border] | [Border, Border, Border] | [Border, Border] | [Border];
  paralax?: boolean;
}

/**
 * Overwrite Style
 *
 * Overwrite Style component will enforce a style to contain components.
 **/
@Component({})
export class OverwriteStyle extends BaseComponent<IOverwriteStyle, unknown> {
  /**
   * Overwrite Style component will enforce a style to contain components.
   */
  @Prop({ type: Object })
  overwrite: IOverwrites;

  @appContextStore.Getter('isDesktop')
  isDesktop: boolean;

  render() {
    return this.$slots.default;
  }

  mounted() {
    const el = this.$el as HTMLElement;

    if (el) {
      if ('backgroundImg' in this.overwrite && this.overwrite.backgroundImg && this.overwrite.backgroundImg?.length) {
        const backgroundImage = this.overwrite.backgroundImg.startsWith('/')
          ? this.overwrite.backgroundImg
          : '/' + this.overwrite.backgroundImg;

        el.style.background = `url(${
          this.extended.$config.zento.theme.storeData.storeUrl + backgroundImage
        }) center center no-repeat`;

        if (this.isDesktop && 'paralax' in this.overwrite && this.overwrite.paralax) {
          el.style['background-size'] = 'cover';
          el.style['background-attachment'] = 'fixed';
        }
      } else if ('background' in this.overwrite) {
        el.style.background = this.asColor(this.overwrite.background);
      }

      if ('border' in this.overwrite && this.overwrite.border && this.overwrite.border.length > 0) {
        const aux = this.overwrite.border;

        let borders: [Border, Border, Border, Border];

        if (Array.isArray(aux)) {
          if (aux.length === 1) {
            borders = [aux[0], aux[0], aux[0], aux[0]];
          } else if (aux.length === 2) {
            borders = [aux[0], aux[1], aux[0], aux[1]];
          } else if (aux.length === 3) {
            borders = [aux[0], aux[1], aux[2], aux[1]];
          } else {
            borders = aux;
          }
        } else {
          borders = [aux, aux, aux, aux];
        }

        ['Top', 'Left', 'Bottom', 'Right'].forEach((s, i) => {
          const border = borders[i];

          if ('style' in border) {
            el.style[`border${s}Style`] = border.style;
          }

          if ('width' in border) {
            el.style[`border${s}Width`] = border.width;
          }

          if ('color' in border) {
            el.style[`border${s}Color`] = this.asColor(border.color);
          }
        });
      }
    }
  }

  private toCSS3VariableName(type: 'color', path: string) {
    if (path) {
      const segments = path.split('.').filter(Boolean);

      switch (type) {
        case 'color':
          return `--color-current-${segments.join('-')}`;
      }
    }
  }

  private asColor(path: string) {
    const base = this.toCSS3VariableName('color', path);

    return `hsla(${['h', 's', 'l', 'a'].map((p) => `var(${base}-${p})`).join(', ')})`;
  }
}
