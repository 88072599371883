import { Component, BaseComponent, Prop } from '@zento-lib/components';
import type { Usp } from 'theme/stores/cms/types';
import { decodePhpHtmlString } from '@zento-lib/util/html';

import { InnerHTML } from '../../../atom/InnerHTML';

import type { IHomePageUsp } from './Usp.d';
import style from './style.scss';

/**
 * Homepage Usp
 *
 * Renders usp with custom text (specified by the shop administrator)
 */
@Component({})
export class HomePageUsp extends BaseComponent<IHomePageUsp, unknown> {
  /**
   * An array containing component information (like usp content, etc.)
   */
  @Prop({ type: Object, required: true })
  uspData: Usp;

  render() {
    return (
      <section class={[style.uspHome, style.container]} key='usp'>
        {this.uspData.heading ? (
          <h2
            class={{
              [style.centerTitle]: this.uspData.heading_position === 'center',
              [style.rightTitle]: this.uspData.heading_position === 'right',
            }}
            key='top-categories-title'>
            {this.uspData.heading}
          </h2>
        ) : null}

        <InnerHTML contents={decodePhpHtmlString(this.uspData.item.content || '')} />
      </section>
    );
  }
}
