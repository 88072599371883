import { Component, BaseComponent, Prop, namespace } from '@zento-lib/components';
import { decodePhpHtmlString } from '@zento-lib/util/html';
import { AppContextStore, KEY as appContextKey } from 'theme/@types/zento/stores/applicationContext';
import type { PageWidgetData } from 'theme/stores/cms/types';
import { InnerHTML } from '@zento/modules/atom/InnerHTML';
import { MainImage } from '@zento/modules/atom/MainImage/MainImage';
import { Link } from '@zento/modules/atom/Link/Link';
import { Button } from '@zento/modules/atom/Button/Button';

import type { ICmsPages } from './CmsPages.d';
import style from './style.scss';

const appContextStore = namespace<AppContextStore>(appContextKey);

/**
 * Homepage Category List Image
 *
 * Renders a list with specific categories links and images
 */
@Component({})
export class CmsPages extends BaseComponent<ICmsPages, unknown> {
  /**
   * An object containing component data (heading title and components links and images)
   */
  @Prop({ type: Object, required: true })
  cmsPages: PageWidgetData;

  @appContextStore.Getter('isDesktop')
  isDesktop: boolean;

  /**
   * Determines config image sizes
   */
  get imageSizes() {
    return this.extended.$config.zento.images.pageEventWidget;
  }

  render() {
    return (
      <section
        class={{
          [style.cmsPages]: true,
        }}
        key='cms-pages-content'>
        <div
          class={{
            [style.container]: true,
            [style.smallContainer]: this.cmsPages.width === 'small',
            [style.largeContainer]: this.cmsPages.width === 'large',
            [style.fullContainer]: this.cmsPages.width === 'full_width',
          }}>
          {this.cmsPages.heading ? (
            <h2
              class={{
                [style.centerTitle]: this.cmsPages.heading_position === 'center',
                [style.rightTitle]: this.cmsPages.heading_position === 'right',
              }}
              key='top-categories-title'>
              {this.cmsPages.heading}
            </h2>
          ) : null}

          <ul class={style.cmsPagesItems}>
            {this.cmsPages.items.map((p) => {
              const image = {
                src: '/' + p.image,
                loading: '',
              };

              return (
                <li>
                  <Link to={'/i/' + p.page_uri}>
                    <MainImage
                      image={image}
                      folder=''
                      width={this.imageSizes.width}
                      height={this.imageSizes.height}
                      tabletWidth={this.imageSizes.tabletWidth}
                      tabletHeight={this.imageSizes.tabletHeight}
                      desktopWidth={this.imageSizes.desktopWidth}
                      desktopHeight={this.imageSizes.desktopHeight}
                      key='cms-page-image'
                    />
                  </Link>

                  {p.description ? (
                    <InnerHTML
                      class={style.description}
                      contents={decodePhpHtmlString(p.description)}
                      key='cms-page-description'
                    />
                  ) : null}

                  {p.button_label && p.button_type !== 'link' ? (
                    <Button
                      class={style.button}
                      styleType={p.button_type}
                      key='cms-page-button'
                      handler={() => this.$router.push(this.extended.localizedRoute('/i/' + p.page_uri))}>
                      <span>{p.button_label}</span>
                    </Button>
                  ) : p.button_label && p.button_type === 'link' ? (
                    <Link to={'/i/' + p.page_uri} class={style.link}>
                      {p.button_label}
                    </Link>
                  ) : null}
                </li>
              );
            })}
          </ul>
        </div>
      </section>
    );
  }
}
