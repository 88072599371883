import { Component, BaseComponent, namespace, Prop } from '@zento-lib/components';
import { FormatDate } from '@zento-lib/components/i18n/FormatDate';
import { Carousel, Slide } from '@zento-lib/components/Carousel';
import { BlogArticle } from 'theme/stores/blog/blog';
import { AppContextStore, KEY as appContextKey } from 'theme/@types/zento/stores/applicationContext';
import type { BlogArticlesData } from 'theme/stores/cms/types';

import { BlogMixin } from '../mixins';
import { Link } from '../../../atom/Link/Link';
import { MainImage } from '../../../atom/MainImage/MainImage';

import type { IBlogWidget } from './BlogWidget.d';
import style from './style.scss';

const appContextStore = namespace<AppContextStore>(appContextKey);

interface IBlogMixin {
  blogCatName: (id: string) => string;
}

/**
 * Blog Posts Widget
 *
 * Blog Posts Widget component rendering articles items
 */
@Component({
  mixins: [BlogMixin],
})
export class BlogWidget extends BaseComponent<IBlogWidget, IBlogMixin> {
  private static T = {
    seeAll: 'see_all',
    seeMoreLink: 'see_more_link',
  };

  /**
   * Determines widget data
   */
  @Prop({ type: Object, required: true })
  data: BlogArticlesData;

  @appContextStore.Getter('isDesktop')
  isDesktop: boolean;

  render() {
    const config = this.extended.$config.zento.images.blogList;
    const articles = this.data.items;

    return (articles as BlogArticle[]).length ? (
      <div class={style.blogWrapper}>
        {this.data.heading ? (
          <h2
            class={{
              [style.title]: true,
              [style.centerTitle]: this.data.heading_position === 'center',
              [style.rightTitle]: this.data.heading_position === 'right',
              [style.seeAllAfter]: this.data.seeAllPosition === 'left' && this.data.heading_position === 'left',
            }}
            key='product-widget-title'>
            <span>{this.data.heading}</span>
            <div>
              {this.data.seeAll ? (
                <Link
                  to={'/blog'} // TODO: adjust if needed
                  class={{
                    [style.seeAll]: true,
                    [style.centerSeeAll]: this.data.seeAllPosition === 'center',
                    [style.rightSeeAll]: this.data.seeAllPosition === 'right',
                  }}
                  key='see-all'>
                  {this.getTranslation({ id: BlogWidget.T.seeAll })}
                </Link>
              ) : null}
            </div>
          </h2>
        ) : null}

        <div class={style.blogWidgetWrapper}>
          <Carousel
            perPage={parseFloat(this.data.productsToDisplay)}
            perPageCustom={[
              [320, parseFloat(this.data.perPageMobilePortrait)],
              [480, parseFloat(this.data.perPageMobileLandscape)],
              [767, parseFloat(this.data.perPageTablet)],
              [1025, parseFloat(this.data.perPageDesktop)],
            ]}
            mouseDrag={true}
            navigationEnabled={this.data.navigationEnabled}
            paginationEnabled={this.data.paginationEnabled}
            paginationActiveColor={this.data.paginationActiveColor}
            paginationSize={parseFloat(this.data.paginationSize)}
            centerMode={this.data.centerMode}
            scrollPerPage={this.isDesktop}
            easing='ease-in-out'
            speed={this.isDesktop ? 500 : 200}
            class={style.blogWidgetItems}
            key='blog-widget-carousel'>
            {(articles as BlogArticle[]).map((item) => {
              const image = {
                src: item.image_list.replace(/\/\//g, '/') || '',
                loading: '',
              };

              return (
                <Slide key={item.entity_id} class={style.blogWidgetListItem}>
                  <Link to={'/b/' + item.url_key}>
                    <div class={style.blogWidgetArticleWrapper}>
                      <MainImage
                        image={image}
                        width={config.width}
                        height={config.height}
                        tabletWidth={config.tabletWidth}
                        tabletHeight={config.tabletHeight}
                        desktopWidth={config.desktopWidth}
                        desktopHeight={config.desktopHeight}
                        heightFallback={0}
                        folder='/media/'
                        key={item.entity_id}
                      />
                      <div class={style.blogWidgetArticleContent}>
                        {!config.enableBtnLink ? (
                          <span>
                            {this.extended.blogCatName(item.categories)}
                            <span class={style.date}>
                              <FormatDate date={item.date_publish} />
                            </span>
                          </span>
                        ) : null}
                        <h3>{item.title}</h3>

                        {config.enableBtnLink ? (
                          <span class={style.seeMoreLink}>{this.getTranslation({ id: BlogWidget.T.seeMoreLink })}</span>
                        ) : null}
                      </div>
                    </div>
                  </Link>
                </Slide>
              );
            })}
          </Carousel>
        </div>
      </div>
    ) : null;
  }
}
