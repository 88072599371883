import { Component, BaseComponent, namespace, Prop } from '@zento-lib/components';
import { Carousel, Slide } from '@zento-lib/components/Carousel';
import { BlogCategory } from 'theme/stores/blog/blog';
import { AppContextStore, KEY as appContextKey } from 'theme/@types/zento/stores/applicationContext';
import type { BlogCategoriesData } from 'theme/stores/cms/types';

import { BlogMixin } from '../mixins';
import { Link } from '../../../atom/Link/Link';
import { MainImage } from '../../../atom/MainImage/MainImage';

import type { IBlogCategories } from './BlogCategories.d';
import style from './style.scss';

const appContextStore = namespace<AppContextStore>(appContextKey);

interface IBlogMixin {
  blogCatName: (id: string) => string;
}

type BlogWidgetCategory = BlogCategory & { image?: string };

/**
 * Blog Posts Widget
 *
 * Blog Posts Widget component rendering articles items
 */
@Component({
  mixins: [BlogMixin],
})
export class BlogCategories extends BaseComponent<IBlogCategories, IBlogMixin> {
  /**
   * Determines widget data
   */
  @Prop({ type: Object, required: true })
  data: BlogCategoriesData;

  @appContextStore.Getter('isDesktop')
  isDesktop: boolean;

  render() {
    const imgConfig = this.extended.$config.zento.images.blogList;

    return (this.data.items as BlogWidgetCategory[]).length ? (
      <div class={{ [style.blogWrapper]: true, [style.singleItem]: this.data.items.length === 1 }}>
        {this.data.heading ? (
          <h2
            class={{
              [style.title]: true,
              [style.centerTitle]: this.data.heading_position === 'center',
              [style.rightTitle]: this.data.heading_position === 'right',
            }}
            key='product-widget-title'>
            <span>{this.data.heading}</span>
          </h2>
        ) : null}

        <div class={style.blogWidgetWrapper}>
          <Carousel
            perPage={parseFloat(this.data.perPageDesktop) || 1}
            perPageCustom={[
              [320, parseFloat(this.data.perPageMobilePortrait)],
              [480, parseFloat(this.data.perPageMobileLandscape)],
              [767, parseFloat(this.data.perPageTablet)],
              [1025, parseFloat(this.data.perPageDesktop)],
            ]}
            mouseDrag={true}
            navigationEnabled={this.data.navigationEnabled}
            paginationEnabled={this.data.paginationEnabled}
            paginationActiveColor={this.data.paginationActiveColor}
            paginationSize={parseFloat(this.data.paginationSize)}
            centerMode={this.data.centerMode}
            scrollPerPage={this.isDesktop}
            easing='ease-in-out'
            speed={this.isDesktop ? 500 : 200}
            class={style.blogWidgetItems}
            key='blog-widget-carousel'>
            {(this.data.items as BlogWidgetCategory[]).map((item) => {
              const image = {
                src: item.image.replace(/\/\//g, '/') || '',
                loading: '',
              };

              return (
                <Slide key={item.entity_id} class={style.blogWidgetListItem}>
                  <Link to={'/blog?category=' + item.entity_id}>
                    <div class={style.blogWidgetArticleWrapper}>
                      <MainImage
                        image={image}
                        width={imgConfig.width}
                        height={imgConfig.height}
                        tabletWidth={imgConfig.tabletWidth}
                        tabletHeight={imgConfig.tabletHeight}
                        desktopWidth={imgConfig.desktopWidth}
                        desktopHeight={imgConfig.desktopHeight}
                        heightFallback={0}
                        folder='/media/'
                        key={item.entity_id}
                      />
                      {this.data.showCategoryTitle === 'true' ? (
                        <div class={style.blogWidgetArticleContent}>
                          <p class={style.blogWidgetArticleTitle}>{item.title}</p>
                          <div
                            class={{
                              [style.button]: true,
                              [style.buttonIcon]: this.data.buttonType === 'icon',
                              [style.buttonIconText]: this.data.buttonType === 'iconWithText',
                            }}>
                            {this.data.buttonText ? <p>{this.data.buttonText}</p> : null}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Link>
                </Slide>
              );
            })}
          </Carousel>
        </div>
      </div>
    ) : null;
  }
}
