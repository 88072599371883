import { Component, BaseComponent, Prop } from '@zento-lib/components';
import type { BannerData } from 'theme/stores/cms/types';

import { MainImage } from '../../../atom/MainImage/MainImage';
import { Button } from '../../..//atom/Button/Button';

import type { IHomepageMainBanner } from './MainBanner.d';
import style from './style.scss';

/**
 * Homepage Main Banner
 *
 * Renders an image with custom text (specified by the shop administrator)
 */
@Component({})
export class HomepageMainBanner extends BaseComponent<IHomepageMainBanner, unknown> {
  /**
   * An array containing component information (like image path, banner content, etc.)
   */
  @Prop({ type: Array, required: true })
  bannerData: BannerData[];

  @Prop({ type: String, required: false, default: 'small' })
  componentWidth?: 'full_width' | 'large' | 'small';

  get mainImageSizes() {
    return this.extended.$config.zento.images.bannerMainImage;
  }

  get secondaryImageSizes() {
    return this.extended.$config.zento.images.bannerSecondaryImage;
  }

  render() {
    return (
      <section class={style.bannerWrapper} key='main-banner'>
        <ul class={style.bannerBox}>
          {this.bannerData.map((item) => {
            return (
              <li class={style.bannerItem} key='banner-item'>
                <MainImage
                  image={item.image}
                  alt={item.image.alt}
                  width={this.mainImageSizes.width}
                  height={this.mainImageSizes.height}
                  tabletWidth={this.mainImageSizes.tabletWidth}
                  tabletHeight={this.mainImageSizes.tabletHeight}
                  desktopWidth={this.mainImageSizes.desktopWidth}
                  desktopHeight={this.mainImageSizes.desktopHeight}
                  class={style.mainBannerImg}
                  key='main-banner-img'>
                  <div class={style.bannerRightContent} slot='after'>
                    <MainImage
                      image={item.rightContent.image}
                      alt={item.rightContent.image.alt}
                      folder='/catalog/category/'
                      width={this.secondaryImageSizes.width}
                      height={this.secondaryImageSizes.height}
                      tabletWidth={this.secondaryImageSizes.tabletWidth}
                      tabletHeight={this.secondaryImageSizes.tabletHeight}
                      desktopWidth={this.secondaryImageSizes.desktopWidth}
                      desktopHeight={this.secondaryImageSizes.desktopHeight}
                      class={style.secondBannerImg}
                      key='second-banner-img'
                    />
                    <h2 class={style.bannerLeftText} key='banner-left-text'>
                      {item.rightContent.leftText}
                    </h2>
                    <h2 class={style.bannerRightText} key='banner-right-text'>
                      {item.rightContent.rightText}
                    </h2>
                  </div>
                </MainImage>
                <div class={style.container} key='main-banner-content'>
                  <div class={[style.bannerContent, style.leftCenter]}>
                    <h2 key='main-banner-content-title'>{item.content.title}</h2>
                    <p key='main-banner-content-text'>{item.content.text}</p>
                    <Button
                      styleType='primary'
                      handler={() => this.$router.push(this.extended.localizedRoute(item.content.url))}
                      class={style.bannerContentLink}
                      key='main-banner-content-link'>
                      <span>{item.content.name}</span>
                    </Button>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </section>
    );
  }
}
