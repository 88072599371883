import { Component, BaseComponent, Prop } from '@zento-lib/components';
import { ProductsData } from 'theme/stores/cms/types';

import type { FilterType } from '../../types/Category';
import { ProductWidget } from '../../../atom/Product/Widget/Widget';

import { IHomepageProducts } from './Products.d';

/**
 * Products Widget
 *
 * Renders a list of specific products (depends on custom applied filters by the shop administrator)
 */
@Component({})
export class HomepageProducts extends BaseComponent<IHomepageProducts, unknown> {
  /**
   * An object containing data about component (heading title and chosen products)
   */
  @Prop({ type: Object, required: true })
  data: ProductsData;

  /**
   * An object of filters for image swatch selection
   */
  @Prop({ type: Object, required: false })
  chosenFilters?: { [filterType in FilterType['type']]: FilterType };

  render() {
    return <ProductWidget widgetData={this.data} chosenFilters={this.chosenFilters} key={this.data.heading} />;
  }
}
